/* Vanilla slider - v1.0.2 */
/* Author : Romain Larose */

class vanillaSlider {

  constructor(opts) {

    /* Merge the options passed in with the defaults */
    this.config = {
      sliderClass: ".slider",
      frameClass: ".frame",
      rollClass: ".roll",
      slideClass: ".slide",
      prevButtonClass: ".ctrl.prev",
      nextButtonClass: ".ctrl.next",
      timeMS: 400,
      controls: true,
      ...opts
    };

    this.sliderDOM = document.querySelector(this.config.sliderClass);
    this.rollDOM = this.sliderDOM.querySelector(this.config.rollClass);
    this.slidesArrayDOM = Array.from(this.rollDOM.querySelectorAll(this.config.slideClass));
    this.slideCount = this.slidesArrayDOM.length;
    this.slideWidth = this.#getVisibleSlideWidth();
    this.actualPos = 0;
    this.rollTranslation = 0;

    /* Specific CSS */
    this.rollDOM.style.transition = `transform ${this.config.timeMS}ms ease`;

    this.#initAbsPos(); // Forcing slides position to absolute at loading
    /* If controls enabled, adding event listeners on them */
    if (this.config.controls) this.#addEventListeners();
  }


  #initAbsPos() {
    this.slidesArrayDOM.map((s, i) => {
      s.style.position = "absolute";
      s.style.left = i * this.slideWidth + "px";
    });
  }

  #getVisibleSlideWidth() {
    /* Returns the actual value of the visible slide width within the frame */
    const frame = this.sliderDOM.querySelector(this.config.frameClass);
    const viewXFrame = frame.getBoundingClientRect().x;
    const borderLeftFrame = +getComputedStyle(frame).borderWidth.slice(0, -2);
    const viewXFrameInner = viewXFrame + borderLeftFrame;

    let visibleSlide = this.slidesArrayDOM.find(s => s.getBoundingClientRect().x === viewXFrameInner);

    /* If no slide is right at the frame inner X start, pick the first one */
    if (!visibleSlide) visibleSlide = slides[0];

    return visibleSlide.offsetWidth
  }

  #getElementLeft(el) {
    /* Returns the actual value of element left position as an integer (px) */
    let propertyStr = el.style.left.match(/(.+?)p/);
    if (!propertyStr) return null
    return +propertyStr[1]
  }

  #cyclingSlides(step, dir) {
    // NB: 'step' is not used yet

    /* Sorting actual slide list with real absolute positions ordering */
    const sortedSlides = this.slidesArrayDOM.sort(
      (a, b) => this.#getElementLeft(a) - this.#getElementLeft(b)
    );

    if (dir === 'next') {
      sortedSlides[0].style.left = this.#getElementLeft(sortedSlides[this.slideCount - 1]) + this.slideWidth + 'px';
    }
    if (dir === 'prev') {
      sortedSlides[this.slideCount - 1].style.left = this.#getElementLeft(sortedSlides[0]) - this.slideWidth + 'px';
    }

  }

  prevSlide() {
    /* Main function to move slides to the left */
    this.actualPos--;
    this.rollTranslation = this.rollTranslation + this.slideWidth;
    this.rollDOM.style.transform = 'translate(' + this.rollTranslation + 'px)';
    this.#cyclingSlides(1, 'prev');

    /* Make sure actualPos stays between 0 and slide count */
    if (this.actualPos < 0) {
      this.actualPos = this.slideCount - 1;
    }
  }

  nextSlide() {
    /* Main function to move slides to the right */
    this.actualPos++;
    this.rollTranslation = this.rollTranslation - this.slideWidth;
    this.rollDOM.style.transform = 'translate(' + this.rollTranslation + 'px)';

    /* Deplacing slides after translation is done to avoid blanks */
    setTimeout(() => this.#cyclingSlides(1, 'next'), this.config.timeMS);

    /* Make sure actualPos stays between 0 and slide count */
    if (this.actualPos === this.slideCount) {
      this.actualPos = 0;
    }
  }

  #addEventListeners() {
    /* Click on "PREVIOUS" control button */
    this.sliderDOM.querySelector(this.config.prevButtonClass).addEventListener("click", e => {
      this.prevSlide();
    });

    /* Click on "NEXT" control button */
    this.sliderDOM.querySelector(this.config.nextButtonClass).addEventListener("click", e => {
      this.nextSlide();
    });
  }
}

export { vanillaSlider }